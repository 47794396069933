.container-alert {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 12px;
  background-color: #ffbc00;
  overflow: hidden;
}
.info-text {
  color: #222222;
  font-size: 1.2rem;
  text-align: center;
}
