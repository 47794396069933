.container {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.images img {
  width: 65px;
}

.title {
  color: #b31d15;
  font-size: 1.2rem;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.separator {
  height: 92%;
  width: 2px;
  background-color: #bfbfbf;
}
