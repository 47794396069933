.wrapper {
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3rem;
}

.text-center{
  text-align: center;
}

.flex-home{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.btn-nav{
  width: 180px;
  height: 180px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(133,133,133,0.75);
-webkit-box-shadow: 0px 0px 15px 0px rgba(133,133,133,0.75);
-moz-box-shadow: 0px 0px 15px 0px rgba(133,133,133,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.btn-nav img{
  height: 60px;
}

.btn-nav p{
  color: #b31d15;
  font-size: 16px;
  font-weight: 600;
}