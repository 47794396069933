.container-generator {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  /* width: auto; */
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.wrapper-input {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.wrapper-input label {
  color: #b31d15;
  font-size: 1.2rem;
  /* font-weight: semi-bold; */
  text-transform: uppercase;
}

.wrapper-input input {
  border: none;
  outline: none;
  border-bottom: solid 1.5px #b31d15;
  padding: 0.3rem;
  font-size: 1rem;
  /* background-color: #f9f9f9; */
  transition: all 0.3s ease;
}

.wrapper-input input:hover {
  border-bottom: solid 2.5px #97140d;
}
.wrapper-input input:focus {
  border-bottom: solid 2.5px #97140d;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.button {
  border: none;
  padding: 0.5rem 1.5rem;
  color: white;
  background-color: #b31d15;
  font-size: 1rem;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: #97140d;
}

.copy-button {
  border: none;
  padding: 0.5rem;
  color: white;
  background-color: #5e80db;
  font-size: 1rem;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.copy-button:hover {
  background-color: #5071ca;
}

.wrapper-link {
  background-color: #f7fdfb;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #1e8065;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.info-link {
  color: #1e8065;
  font-size: 0.9rem;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 600px) {
  .info-link {
    width: 230px;
  }
}
