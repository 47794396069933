.footer-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 2rem;
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 500px;
  justify-content: center;
  align-items: center;
}

.barra-footer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 12px;
  background-color: #3bd39c;
  overflow: hidden;
}
.info-footer {
  color: #222222;
  text-align: center;
}
